import { useState } from "react";
import { Drawer } from "antd";
import GetInTouchForm from "@/components/get-in-touch/GetInTouchForm";
import { LoadScript, GoogleMap, Marker } from "@react-google-maps/api";
import "@fortawesome/fontawesome-free/css/all.css";
import { useTranslation } from "react-i18next";
//加入新组件 要求 node-v调整至 至少 18.18.0
import wx from "@/assets/img/wx.png";


const ContactUs = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showWX, showWXchange] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  // Google Maps configuration
  const mapContainerStyle = {
    width: "100%",
    height: "400px",
  };
  const { t, i18n } = useTranslation();

  const center = {
    lat: -34.905439,// Replace with your latitude
    lng: 138.6099002,  // Replace with your longitude
  };

  const mapSrc = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d687.6981823573348!2d138.60441464956787!3d-34.92562454549074!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ab0cf5b3aa80087%3A0x3195c74e901eddb6!2s147%20Pirie%20St%2C%20Adelaide%20SA%205000!5e0!3m2!1sen!2sus!4v1729924425045!5m2!1sen!2sus&hl=en&gl=US";
  const zhMapSrc = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d687.6981823573348!2d138.60441464956787!3d-34.92562454549074!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ab0cf5b3aa80087%3A0x3195c74e901eddb6!2s147%20Pirie%20St%2C%20Adelaide%20SA%205000!5e0!3m2!1szh-CN!2scn!4v1729924425045!5m2!1szh-CN!2scn&hl=zh-CN&gl=CN";
  return (
    <>
      <div className="bg-[#faf7f5] lg:pt-10 pt-6 lg:pb-16 pb-8">
        <div className="page-content flex lg:flex-row flex-col justify-between">
          <div className="lg:pr-5 lg:w-5/12 border-neutral-300 lg:border-r-2 lg:border-b-0 lg:pb-0 pb-10 border-b-2">
            <h2 className="lg:text-6xl md:text-5xl font-lab-antiqua text-sky-950 mb-5 md:mb-8">
              {t('contactUsTitle')}
            </h2>
            <p className="pb-6 mb-6 text-sm text-gray-700">
              {t('contactUsDesc')}
            </p>

            <button
              className="more-btn btn-hover bg-sky-950 text-white"
              onClick={showModal}
            >
              {t('contactUsBtn')}
            </button>
          </div>

          <div className="lg:w-3/5 lg:-mt-5 mt-6 lg:pl-10 pl-3">
            {/* Google Map Component */}

            {i18n.language === 'en' ? 
              <iframe 
                title="DDDI Group Office Location Map" 
                src={mapSrc} 
                allowFullScreen 
                style={{ width: "100%", height: "600px", borderRadius: 2 }}
              /> : 
              <iframe 
                title="DDDI集团办公地点地图" 
                src={zhMapSrc} 
                allowFullScreen 
                style={{ width: "100%", height: "600px", borderRadius: 2 }}
              />
            }

            {/* <LoadScript googleMapsApiKey="AIzaSyClqiJpCr27Cxp3AOPwv6_B1kMZZP8ARAM">
              <GoogleMap
                mapContainerStyle={mapContainerStyle}
                center={center}
                zoom={15}
              >
                <Marker
                  position={center}
                  label={{
                    text: "DDDI Group",
                    color: 'white',
                    fontSize: '16px',
                    fontWeight: 'bold',
                  }}
                  icon={{
                    url: 'http://maps.google.com/mapfiles/ms/icons/red-dot.png', // 使用 Google Map 自带的红色图标
                  }}
                />
              </GoogleMap>
            </LoadScript> */}

            {/* Contact Information Section */}
            <div className="flex flex-wrap lg:flex-nowrap lg:mt-5 mt-6 lg:gap-20 gap-6 text-sm text-gray-700">
              {/* Left Section */}
              <div className="flex flex-col items-start space-y-4">
                {/* Address */}
                <div>
                  <strong>{t('addressLabel')}</strong>
                  <br />
                  {t('addressLine1')}
                  <br />
                  {t('addressLine2')}
                </div>

                {/* Phone */}
                <div>
                  <strong>{t('phoneLabel')}</strong>
                  <br />
                  {t('phoneNumber')}
                </div>

                {/* Email */}
                <div>
                  <strong>{t('emailLabel')}</strong>
                  <br />
                  {t('emailAddress')}
                </div>
              </div>

              {/* Right Section */}
              <div className="flex flex-row lg:flex-col items-center lg:items-end lg:ml-auto lg:space-x-0 lg:space-y-4" style={{alignItems:"end"}}>
                {/* LinkedIn */}
                <a
                  href="https://www.linkedin.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-700 hover:text-blue-800"
                >
                  <i className="fab fa-linkedin fa-2x"></i>
                </a>

                {/* WeChat */}
                <div
                  className="text-gray-700 hover:text-green-800"
                  onClick={()=>showWXchange(!showWX)}
                  style={{position:'relative',margin:"0 2rem"}}
                >
                  <i className="fab fa-weixin fa-2x"></i>
                  {showWX && <div style={{position:"absolute",top:"calc(-10vw - 60px)",left:"-8vw",width:"10vw",height:"10vw"}}>
                      <img src={wx}></img>
                    </div>}
                </div>

                {/* Xiaohongshu */}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.xiaohongshu.com/user/profile/6642b1750000000007007702?xsec_token=&xsec_source=pc_search"
                  className="text-gray-700 hover:text-red-600"
                >
                  <i className="fas fa-book fa-2x"></i>
                </a>
                <div
                    className="text-gray-700 hover:text-yellow-600"
                    style={{margin:"0 2rem"}}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => { window.location.href = 'mailto:info@dddi.com.au' }}
                  >
                    <i className="fas fa-envelope fa-2x"></i>
                  </div>
              </div>
            </div>


          </div>
        </div>
      </div>

      <Drawer
        title=""
        placement="bottom"
        height={"100vh"}
        width={"100vw"}
        onClose={handleClose}
        open={isModalOpen}
        closeIcon={false}
        styles={{
          header: {
            borderBottom: `0px`,
          },
          body: {
            padding: `0px`,
          },
        }}
      >
        <GetInTouchForm onClose={handleClose}></GetInTouchForm>
      </Drawer>
    </>
  );
};

export default ContactUs;
