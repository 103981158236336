import React from "react";
import { useTranslation } from "react-i18next";
import Layout from "@/common/Layout";
import SEO from "@/common/SEO";
import ContactUs from "@/components/contact-us/ContactUs";
import Separator from "../careers/Separator";
import TeamThree from "../people/Teammember";
import Banner from "@/components/banner/Banner";

const TeamPage = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <SEO title={t('ourTeam')} />
      <Banner
                title={t('ourTeam')}
                bgColor="#F2EDE8"
                description={''}
                textColor="#0A1E41"
                img={`../images/team/Team.jpg`}
            />
      <div className="page-content">
        {/* <h2 className="text-color-primary font-lab-antiqua text-3xl md:text-4xl lg:text-5xl mb-7">
          {t('ourTeam')}
        </h2> */}
        <Separator />
        <div className="rwt-team-area rn-section-gap">
          <div className="container">

            <TeamThree />
          </div>
        </div>
        <Separator />
        <div className="flex flex-col gap-y-10 page-content pt-10 pb-16">
          {/* Top Section: Text */}
          <div className="flex flex-col">

            <p className="text-2xl text-gray-1000 mb-8 ml-8">
              {t('75')}
            </p>


            <div className="flex items-center justify-center">
              <img
                src="../images/team/Team.jpg"
                alt="DDDI Group Illustration"
                className="w-full max-w-4xl lg:max-w-6xl object-cover rounded-lg shadow-lg"
              />

            </div>



          </div>

          {/* Bottom Section: Image */}

        </div>



        {/* Brand Story Details Section */}
        <div className="flex flex-col lg:flex-row gap-x-10 page-content pt-10 pb-16">
          {/* Left Side: Image */}
          <div className="flex-1 flex items-center justify-center mb-10 lg:mb-0">
            <img
              src="../images/DDDI Group/DSC06977.jpg"
              alt="DDDI Group Illustration"
              className="w-full max-w-md lg:max-w-lg object-cover rounded-lg shadow-lg"
            />
          </div>

          {/* Right Side: Text */}
          <div className="flex-1">
            <div className="flex flex-col">
              <p className="text-lg text-gray-700 mb-8">
                {t('53')}
              </p>


            </div>
          </div>


        </div>

        <div className="flex flex-col lg:flex-row gap-x-10 page-content pt-10 pb-16">
          {/* Left Side: Image */}


          {/* Right Side: Text */}
          <div className="flex-1">
            <div className="flex flex-col">
              <p className="text-lg text-gray-700 mb-8">
                {t('65')}
              </p>


            </div>
          </div>



          <div className="flex-1 flex items-center justify-center mb-10 lg:mb-0">
            <img
              src="../images/DDDI Group/DSC07067.jpg"
              alt="DDDI Group Illustration"
              className="w-full max-w-md lg:max-w-lg object-cover rounded-lg shadow-lg"
            />
          </div>





        </div>
      </div>
      <ContactUs />
    </Layout>
  );
};

export default TeamPage; 