import React, { useEffect } from "react";

import { useTranslation } from "react-i18next";
import Layout from "@/common/Layout";
import SEO from "@/common/SEO";
import Banner from "@/components/banner/Banner";
import ContactUs from "@/components/contact-us/ContactUs";
import ImgB from "@/assets/img/b.jpg";

const VrArPage = () => {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <SEO title={t('vrAndAr')} />
      <Layout>
        <Banner
          title={t('vrAndAr')}
          bgColor="#F2EDE8"
          textColor="#0A1E41"
          img={'/images/researches/Research3.jpg'}
        />

        <div className="flex flex-col page-content pt-16 pb-20">
          <div className="flex flex-col md:flex-1 max-w-7xl mx-auto">
            <div className="flex flex-col w-full">
              <h2 className="text-4xl font-bold mb-8 text-gray-900">{t('vrArTitle')}</h2>
              <p className="text-xl leading-relaxed text-gray-700 mb-12 max-w-4xl">
                {t('vrArDesc')}
              </p>
              {/* <div className="relative w-full mb-20">
                <img 
                  src="/images/researches/Research3.jpg"
                  alt="VR/AR Overview" 
                  className="w-full h-[600px] object-cover rounded-xl shadow-lg"
                />
                <div className="absolute inset-0 bg-black opacity-10 rounded-xl"></div>
              </div> */}
            </div>
            <div className="flex flex-col w-full space-y-24">
              <div className="flex flex-col md:flex-row gap-16 items-center">
                <div className="w-full md:w-1/3 group">
                  <div className="pr-8 bg-white p-8 rounded-xl shadow-lg hover:shadow-xl transition-all duration-300 h-full">
                    <div className="flex items-center mb-6">
                      <div className="w-12 h-12 bg-blue-100 rounded-full flex items-center justify-center mr-4">
                        <svg className="w-6 h-6 text-blue-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z" />
                        </svg>
                      </div>
                      <h3 className="text-3xl font-semibold text-gray-900">{t('vrArFeature1Title')}</h3>
                    </div>
                    <p className="text-lg leading-relaxed text-gray-700">
                      {t('vrArFeature1Desc')}
                    </p>
                  </div>
                </div>

                <div className="w-full md:w-1/3 group">
                  <div className="pr-8 bg-white p-8 rounded-xl shadow-lg hover:shadow-xl transition-all duration-300 h-full">
                    <div className="flex items-center mb-6">
                      <div className="w-12 h-12 bg-green-100 rounded-full flex items-center justify-center mr-4">
                        <svg className="w-6 h-6 text-green-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                      </div>
                      <h3 className="text-3xl font-semibold text-gray-900">{t('vrArFeature2Title')}</h3>
                    </div>
                    <p className="text-lg leading-relaxed text-gray-700">
                      {t('vrArFeature2Desc')}
                    </p>
                  </div>
                </div>

                <div className="w-full md:w-1/3 group">
                  <div className="pr-8 bg-white p-8 rounded-xl shadow-lg hover:shadow-xl transition-all duration-300 h-full">
                    <div className="flex items-center mb-6">
                      <div className="w-12 h-12 bg-purple-100 rounded-full flex items-center justify-center mr-4">
                        <svg className="w-6 h-6 text-purple-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z" />
                        </svg>
                      </div>
                      <h3 className="text-3xl font-semibold text-gray-900">{t('vrArFeature3Title')}</h3>
                    </div>
                    <p className="text-lg leading-relaxed text-gray-700">
                      {t('vrArFeature3Desc')}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            </div>
        </div>
        <ContactUs />
      </Layout>
    </>
  );
};

export default VrArPage;