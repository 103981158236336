const Banner = (props) => {
  const {
    subTitle,
    title,
    titleNode,
    description,
    img,
    bgColor,
    textColor,
    bottomElement,
  } = props;

  return (
    <>
      <div
        className="w-full flex justify-between md:items-stretch md:flex-row flex-col items-end"
        style={{
          backgroundColor: bgColor,
          color: textColor,
        }}
      >
        <div className="pl-8 xl:pl-16 lg:pl-14 w-full md:w-1/2 py-10 lg:h-full md:self-center">
          {subTitle && <span className="text-xs">{subTitle}</span>}
          {title && (
            <p className="font-lab-antiqua text-4xl lg:text-5xl xl:text-7xl w-full md:w-auto mt-3">
              {title}
            </p>
          )}
          {titleNode}
          {description && (
            <p className="mt-8 lg:w-4/6 xl:text-lg text-sm">{description}</p>
          )}
          {bottomElement && <div>{bottomElement}</div>}
        </div>

        <div className="md:w-1/2 md:h-full md:min-h-80 relative lg:h-full">
          <img
            src={img}
            style={{height: '100%', objectFit: 'cover', width: '100%'}}
            alt=""
            className="w-full h-full object-cover md:min-h-80"
          />
        </div>
      </div>
    </>
  );
};
export default Banner;
