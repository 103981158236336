import SEO from "@/common/SEO";
import Layout from "@/common/Layout";
import Banner from "@/components/banner/Banner";
import Img01 from "@/assets/img/image01.jpg";
import { useTranslation } from "react-i18next";

const BrandStoryDetails = () => {
  const { t } = useTranslation();

  return (
    <>
      <SEO title={t('ourBrand')} />
      <Layout>
        <Banner
          title={t('ourBrand')}
          bgColor="#F2EDE8"
          textColor="#0A1E41"
          img={Img01}
        />
        <div style={{ marginTop: "20px " }} className="page-content ">
          <h2 className="text-center" style={{ background: "#F2EDE8", padding: "40px 0", fontSize: "3rem" }}>"{t('brandSlogan')}"</h2>
          <p style={{ fontSize: "20px", marginTop: "4rem" }}>{t('brandPositioning')}</p>

          <div className="flex flex-col md:flex-row items-center gap-8 mb-12">
            <div className="w-full md:w-1/2">
              <img src="../images/brand/OurMission.jpg" alt="Brand Story" className="w-full h-64 md:h-96 object-cover" />
            </div>
            <div className="w-full md:w-1/2" >
              <h2 style={{ paddingBottom: "2rem" }}>{t('footerMission')}</h2>
              <p className="text-lg text-gray-700">
                {t('brandMission')}
              </p>
            </div>
          </div>
          <div className="flex flex-col-reverse md:flex-row items-center gap-8">
            <div className="w-full md:w-1/2">
              <h2 style={{ paddingBottom: "2rem" }}>{t('59')}</h2>
              <p className="text-lg text-gray-700">
                {t('brandVision')}
              </p>
            </div>
            <div className="w-full md:w-1/2">
              <img src="../images/brand/OurVision.jpg" alt="Our Approach" className="w-full h-64 md:h-96 object-cover" />
            </div>
          </div>

          <div>
            <h2 className="text-center" style={{ padding: "4rem 0 1rem", fontSize: "3rem" }}>{t('coreValues')}</h2>
            <div className="flex flex-col md:flex-row gap-8 justify-center py-8">
              <div className="flex flex-col items-center">
                <img src="../images/brand/img3.png" alt="Core Value 1" className="w-full h-64 md:h-96 object-cover mb-4" />
                <h3 className="text-xl font-semibold text-gray-800">{t('dataCentric')}</h3>
              </div>
              <div className="flex flex-col items-center">
                <img src="../images/brand/img2.png" alt="Core Value 2" className="w-full h-64 md:h-96 object-cover mb-4" />
                <h3 className="text-xl font-semibold text-gray-800">{t('innovativeInsight')}</h3>
              </div>
              <div className="flex flex-col items-center">
                <img src="../images/brand/img1.png" alt="Core Value 3" className="w-full h-64 md:h-96 object-cover mb-4" />
                <h3 className="text-xl font-semibold text-gray-800">{t('holisticSolutions')}</h3>
              </div>
            </div>
          </div>

          <div>
            <h2 style={{ padding: "40px 0", fontSize: "3rem" }}>{t('ourStory')}</h2>
            <p className="text-lg text-gray-700 mb-8">{t('53')}</p>
            <p className="text-lg text-gray-700 mb-8">{t('65')}</p>
            <p className="text-lg text-gray-700 mb-8">{t('66')}</p>
            <p className="text-lg text-gray-700 mb-8">{t('67')}</p>
            <p className="text-lg text-gray-700 mb-8">{t('68')}</p>
            <p className="text-lg text-gray-700 mb-8">{t('69')}</p>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default BrandStoryDetails;
