import { useState, useEffect } from "react";
import SEO from "@/common/SEO";
import Layout from "@/common/Layout";
import Banner from "@/components/banner/Banner";
import CardImg from "@/components/card/card-img/CardImg";
import ImgB from "@/assets/img/b.jpg";
import { useTranslation } from "react-i18next";
import ContactUs from "@/components/contact-us/ContactUs";

const ResearchesPage = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <SEO title={t('bigDataAnalytics')} />
      <Layout>
        <Banner
          title={t('bigData')}
          bgColor="#F2EDE8"
          textColor="#0A1E41"
          img={'/images/researches/Research1.jpg'}
        />

        <div className="flex flex-col page-content pt-16 pb-20">
          <div className="flex flex-col md:flex-1 max-w-7xl mx-auto">
            <div className="flex flex-col w-full">
              <h2 className="text-4xl font-bold mb-8 text-gray-900">{t('researchIntro')}</h2>
              <p className="text-xl leading-relaxed text-gray-700 mb-12 max-w-4xl">
                {t('bigDataResearchDesc')}
              </p>
            </div>

            <div className="flex flex-col w-full space-y-16">
              <div className=" grid-cols-1 md:grid-cols-3 gap-8" style={{display:"grid"}}>
                <div className="bg-white p-8 rounded-xl shadow-lg hover:shadow-xl transition-all duration-300">
                  <div className="flex items-center mb-4">
                    <div className="w-10 h-10 bg-blue-100 rounded-full flex items-center justify-center mr-3">
                      <svg className="w-6 h-6 text-blue-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6" />
                      </svg>
                    </div>
                    <h4 className="text-xl font-semibold text-gray-900">{t('marketCompetition')}</h4>
                  </div>
                  <p className="text-gray-700">{t('marketCompetitionDesc')}</p>
                </div>

                <div className="bg-white p-8 rounded-xl shadow-lg hover:shadow-xl transition-all duration-300">
                  <div className="flex items-center mb-4">
                    <div className="w-10 h-10 bg-green-100 rounded-full flex items-center justify-center mr-3">
                      <svg className="w-6 h-6 text-green-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                      </svg>
                    </div>
                    <h4 className="text-xl font-semibold text-gray-900">{t('decisionEfficiency')}</h4>
                  </div>
                  <p className="text-gray-700">{t('decisionEfficiencyDesc')}</p>
                </div>

                <div className="bg-white p-8 rounded-xl shadow-lg hover:shadow-xl transition-all duration-300">
                  <div className="flex items-center mb-4">
                    <div className="w-10 h-10 bg-red-100 rounded-full flex items-center justify-center mr-3">
                      <svg className="w-6 h-6 text-red-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                      </svg>
                    </div>
                    <h4 className="text-xl font-semibold text-gray-900">{t('investmentRisk')}</h4>
                  </div>
                  <p className="text-gray-700">{t('investmentRiskDesc')}</p>
                </div>
              </div>

              <div className="bg-gray-50 p-8 rounded-xl">
                <h3 className="text-2xl font-bold mb-6 text-gray-900">{t('bigDataImplementationTitle')}</h3>
                <div className=" grid-cols-1 md:grid-cols-2 gap-8" style={{display:"grid"}}>
                  <div className="flex items-start">
                    <div className="flex-shrink-0 w-8 h-8 bg-blue-100 rounded-full flex items-center justify-center mr-4">
                      <span className="text-blue-600 font-semibold">1</span>
                    </div>
                    <div>
                      <h4 className="text-xl font-semibold mb-2">{t('bigDataStep1Title')}</h4>
                      <p className="text-gray-700">{t('bigDataStep1Desc')}</p>
                    </div>
                  </div>

                  <div className="flex items-start">
                    <div className="flex-shrink-0 w-8 h-8 bg-blue-100 rounded-full flex items-center justify-center mr-4">
                      <span className="text-blue-600 font-semibold">2</span>
                    </div>
                    <div>
                      <h4 className="text-xl font-semibold mb-2">{t('bigDataStep2Title')}</h4>
                      <p className="text-gray-700">{t('bigDataStep2Desc')}</p>
                    </div>
                  </div>

                  <div className="flex items-start">
                    <div className="flex-shrink-0 w-8 h-8 bg-blue-100 rounded-full flex items-center justify-center mr-4">
                      <span className="text-blue-600 font-semibold">3</span>
                    </div>
                    <div>
                      <h4 className="text-xl font-semibold mb-2">{t('bigDataStep3Title')}</h4>
                      <p className="text-gray-700">{t('bigDataStep3Desc')}</p>
                    </div>
                  </div>

                  <div className="flex items-start">
                    <div className="flex-shrink-0 w-8 h-8 bg-blue-100 rounded-full flex items-center justify-center mr-4">
                      <span className="text-blue-600 font-semibold">4</span>
                    </div>
                    <div>
                      <h4 className="text-xl font-semibold mb-2">{t('bigDataStep4Title')}</h4>
                      <p className="text-gray-700">{t('bigDataStep4Desc')}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ContactUs />
      </Layout>
    </>
  );
};

export default ResearchesPage; 