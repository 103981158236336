import React from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Layout from "@/common/Layout";
import SEO from "@/common/SEO";
import Banner from "@/components/banner/Banner";
import projectJson from "../projectPage.json";
import ContactUs from "@/components/contact-us/ContactUs";

const DetailPage = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  
  const project = projectJson.find(item => item.id === id);

  if (!project) {
    return <div>Project not found</div>;
  }

  return (
    <Layout>
      <SEO title={project.title} />
      <Banner
        title={project.title}
        bgColor="#F2EDE8"
        textColor="#0A1E41"
        img={project.imgUrl}
      />
      
      <div className="page-content py-16">
        <div className="max-w-4xl mx-auto">
          <div className="mb-8">
            <h1 className="text-4xl font-bold mb-4">{project.title}</h1>
            <p className="text-gray-600">{project.subTitle}</p>
          </div>
          
          <div className="mb-12">
            <img 
              src={project.imgUrl} 
              alt={project.title}
              className="w-full h-auto rounded-lg shadow-lg"
            />
          </div>
          
          <div className="prose max-w-none">
            <p className="text-lg leading-relaxed text-gray-700">
              {project.desc}
            </p>
          </div>
        </div>
      </div>
      
      <ContactUs />
    </Layout>
  );
};

export default DetailPage;
